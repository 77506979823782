import AddressDTO from "@/dto/AddressDTO";

export default class SublimeOfficeUpdateRequest {
    public officeId: number | null = null;

    public title: string | null = null;

    public address = new AddressDTO();

    public phone: string | null = null;
}
